import React from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import { Label } from "./Typography";
import COLORS from "../styles/colors"

import IosArrowRoundForward from "react-ionicons/lib/IosArrowForward";
import IosArrowRoundBack from "react-ionicons/lib/IosArrowBack";
import IosArrowRoundUp from "react-ionicons/lib/IosArrowUp";
import IosArrowRoundDown from "react-ionicons/lib/IosArrowDown";
import MdShare from "react-ionicons/lib/MdShare";

const icons = {
  arrowRight: IosArrowRoundForward,
  arrowLeft: IosArrowRoundBack,
  arrowUp: IosArrowRoundUp,
  arrowDown: IosArrowRoundDown,
  share: MdShare
};

const Button = ({ url, text, icon }) => {
  const Icon = icons[icon];
  return (
    <Link
      to={url}
      css={css`
        display: inline-flex;
        height: 35px;
        align-items: center;
        justify-content: center;
        padding: 0px 18.5px;
        transition: 0.15s all;

        h6 {
          font-weight: 500;
          letter-spacing: 0.1em;
        }

        &:link, &:visited {
          background: ${COLORS.black};
          color: ${COLORS.white};
        }
        
        &:hover {
          cursor: pointer;
          background: ${COLORS.white};
          color: ${COLORS.black};
          border: 1px solid ${COLORS.black};
        }

        @media (min-width: 768px) {
          height: 45px;
          padding: 0px 22.5px;
        }
      `}
    >

      { // add icon on the left
        typeof Icon !== "undefined" && icon.includes("Left") ?
        <Icon color={COLORS.white} /> :
        null
      }

      { // add text label or icon
        typeof text !== "undefined" ?
        <Label>{text}</Label> :
        <Icon color={COLORS.white} />
      }

      { // add icon on the right
        typeof Icon !== "undefined" && (icon.includes("Right") || icon.includes("Up") || icon.includes("Down"))  ?
        <Icon color={COLORS.white} /> :
        null
      }
    </Link>
  );
};

export default Button;